<template>
  <div>
    <Nav></Nav>
    <div class="in">
      <h1 class="evaluation-title">평가시행</h1>
      <div class="way-frame">
        <p>평가를 시행할 방법을 선택해주세요.</p>
        <div class="way-box">
          <ul>
            <li v-if="!isSelectA" @click="clickA">
              <div class="img-box" @mouseover="isMouseoverA = true" @mouseleave="isMouseoverA = false" >
                <img v-if="isMouseoverA === false" class="btn-img" src="@/assets/images/IA/evaluation_0_1.png" alt="">
                <img v-else class="btn-img" src="@/assets/images/IA/evaluation_0_2.png" alt="">
              </div>
              <h3>온라인 평가진행</h3>
              <p>전문가 또는 보호자의 도움 하에<br/>
                아동이 직접 평가</p>
            </li>
            <li class="selected-evaluation" v-else @click="isSelectA = false">
              <div class="img-box">
                <img src="@/assets/images/IA/evaluation_0_2.png" alt="">
              </div>
              <h3>온라인 평가진행</h3>
              <p>전문가 또는 보호자의 도움 하에<br/>
                아동이 직접 평가</p>
            </li>
            <li v-if="!isSelectB" @click="clickB">
              <div class="img-box" @mouseover="isMouseoverB = true" @mouseleave="isMouseoverB = false" >
                <img v-if="isMouseoverB === false" class="btn-img" src="@/assets/images/IA/evaluation_0_3.png" alt="">
                <img v-else class="btn-img" src="@/assets/images/IA/evaluation_0_4.png" alt="">
              </div>
              <h3>오프라인 평가점수 등록</h3>
              <p>전문가가 오프라인에서<br/>체크한 점수를 빠르게 등록</p>
            </li>
            <li class="selected-evaluation" v-else @click="isSelectB = false">
              <div class="img-box">
                <img src="@/assets/images/IA/evaluation_0_4.png" alt="">
              </div>
              <h3>오프라인 평가점수 등록</h3>
              <p>전문가가 오프라인에서<br/>체크한 점수를 빠르게 등록</p>
            </li>
          </ul>
        </div>
        <div class="btn-container">
          <button class="exit-btn" @click="btnCancel">평가 취소</button>
          <button class="next-btn" v-if="isSelectA || isSelectB" @click="nextStep">다음</button>
          <button class="next-btn disabled" v-else disabled>평가 시작</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '../../../components/Nav.vue'

export default {
  name: 'Choice',
  components: {Nav},
  data() {
    return {
      kidsUid: this.$route.params.id,
      selectedEvaluator: '',
      active: 0,
      isSelectA: false,
      isSelectB: false,
      isMouseoverA: false,
      isMouseoverB: false,
    }
  },
  mounted() {
    this.init();
    window.scrollTo(0, 0);
  },
  methods: {
    init() {
      const self = this;
      if (this.$route.params.id == null) {
        self.$router.push(`/kidsList`);
      }
    },
    clickA() {
      const self = this;
      self.isSelectA = true;
      self.isSelectB = false;
      self.isMouseoverB = false;
      self.active = 1;
    },
    clickB() {
      const self = this;
      self.isSelectA = false;
      self.isSelectB = true;
      self.isMouseoverA = false;
      self.active = 0;
    },
    nextStep() {
      const self = this;
      if (self.active === 1) {
        self.$router.push({name: 'onlineGuide', params: {id: `${self.kidsUid}`}})
      } else {
        self.$router.push({name: 'offlineHide', params: {id: `${self.kidsUid}`}})
      }
    },
    btnCancel() {
      const self = this;
      self.$router.push(`/kidsList`);
    },
  }
}
</script>

<style scoped>
.evaluation-title {
  margin-top: 40px;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
}

.way-frame {
  height: 632px;
  margin: 22px 0 80px;
}

.way-frame > p {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  margin-bottom: 66px;
  color: #BCBCBC;
}

.way-frame .way-box {
  width: 760px;
  height: 428px;
  margin: 0 auto;
}

.way-frame .way-box ul {
  display: flex;
  justify-content: space-between;
}

.way-frame .way-box ul li {
  width: 350px;
  height: 428px;
  background: #F2F2F2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #272833;
  border-radius: 20px;
}

.way-frame .way-box ul li h3 {
  font-family: 'Elice_B', serif;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  margin: 14px 0 10px;
}

.way-frame .way-box ul li p {
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.0025em;
}

.way-frame .way-box ul li .img-box {
  width: 200px;
  height: 200px;
  cursor: pointer;

}
.way-frame .way-box ul li .img-box img {
  height: 100%;
  width: 100%;
}

.way-frame .btn-container {
  margin-top: 66px;
  float: right;
}

.way-frame .btn-container .exit-btn {
  width: 126px;
  height: 40px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  border: 0;
  border-radius: 4px;
  background: #71717A;
  margin-right: 10px;
}

.way-frame .btn-container .next-btn {
  width: 126px;
  height: 40px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  border: 0;
  border-radius: 4px;
  background: #4C9AF5;
}

.selected-evaluation {
  width: 350px;
  height: 428px;
  background: #FFFFFF !important;
  box-shadow: 0 4px 15px -2px rgba(0, 0, 0, 0.25), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.disabled {
  background: #AEC5E1 !important;
}

</style>